(function () {
    angular
        .module('app.administration.monthlyAttendance.monthlyAttendanceReport')
        .controller('MonthlyAttendanceReportController', MonthlyAttendanceController);

    function MonthlyAttendanceController(dataservice, log, $q, $state, $rootScope, gettextCatalog, authenticationService, paramHelper, $scope, $compile, $timeout, $interval) {
        var vm = this;
        $rootScope.vm = vm; // jshint ignore: line

        // Page title
        vm.autoSave = undefined;
        vm.readOnlyAttendance = false;
        vm.AthleteUnderEditing = '';
        vm.allowAthleteRefresh = true;
        vm.AthleteUnderEditingText = gettextCatalog.getString('ActualAthlete');
        $rootScope.title = gettextCatalog.getString('MonthlyAttendanceReport');
        vm.openTrainingDiary = openTrainingDiary;
        vm.cellSave = cellSave;
        vm.activePeriods = [];
        var printOpened = false;
        var newAthletesId = -1;
        var prev = '';
        vm.plannedTrainings = undefined;
        vm.SetForAll = SetForAll;
        vm.defaultTeamInformations = false;
        vm.errors = [];
        vm.editedRowId = 0;
        vm.editedColumnName = '';
        vm.selectionMode = ['cell'];
        vm.gridRowSelected = gridRowSelected;
        // Grid's details
        vm.reportParameters = {};
        vm.selectedAthleteName = '';
        vm.yearDataBound = yearDataBound;
        vm.monthDataBound = monthDataBound;
        vm.elem;
        vm.maskObject;
        vm.CopyCellToColumn = CopyCellToColumn;
        var tableEditorTemplate =
            {
                create: function () {
                    var btn = document.createElement('button');
                    btn.setAttribute('type', 'button');
                    btn.textContent = '↕';
                    btn.style.width = '30px';
                    btn.style.minWidth = '30px';
                    btn.style.height = '28px';
                    btn.style.color = 'black';
                    btn.style.marginLeft = '-7px';
                    btn.style.display = 'inline';
                    var txt = document.createElement('input');
                    txt.style.width = '20px';
                    txt.style.float = 'left';
                    //  var elem=document.createElement('div');
                    //  elem.style.display='inline';
                    //  elem.appendChild(txt);
                    //  elem.appendChild(btn);
                    vm.elem = txt;
                    vm.elem.addEventListener('focus', function () {
                        this.select();
                    });
                    return vm.elem;
                },
                read: function () {
                    var res = vm.maskObject.getMaskedValue();
                    return res === '_' ? '' : res;
                },
                destroy: function () {
                    if (vm.maskObject != null) {
                        vm.maskObject.destroy();
                    }
                },
                write: function (args) {
                    vm.allowAthleteRefresh = false;
                    $scope.$apply();
                    var s = args.element.id.toString().replace('GridNew', '');
                    vm.maskObject = new ej2.inputs.MaskedTextBox({
                        minWidth: '20px',
                        mask: '>C',
                        customCharacters: {C: 'bBüÜvVsShH0123-'},
                        value: args.rowData !== angular.isUndefined ? args.rowData[s] : ''.replace(' ', '')
                    });
                    vm.editedRowId = args.rowData['Id'];
                    $compile(args.element[0])($scope);
                    vm.maskObject.appendTo(vm.elem);
                }
            };

        var gridNew;

        function queryCellInfo(args) {
            var element = angular.element(args.cell);
            var sportolo = args.data.IdSportolo;
            if (args.column.field.indexOf('Nap') !== -1) {
                var nap = args.column.field.substring(3);
                var sor = findLine(sportolo);
                if ((args.column.headerText.toString().indexOf(gettextCatalog.getString('MondayAbbr')) !== -1) || (args.column.headerText.toString().indexOf('MondayAbbr') !== -1)) {
                    element.css('background-color', '#d2e4ff');
                }
                if (sor && sor['Nap' + nap] === '0') {
                    element.css('background-color', '#efdcdc');
                }
            }
        }

        //Jelenlét százalék
        vm.presencePercentSum = 0;
        //Jelenlét számolásához kell
        vm.presenceTotalSum = 0;
        vm.presencePlannedTotalSum = 0;
        //Évek (2010-től az aktuális évig)
        vm.years = [];
        for (var ev = 2010; ev <= new Date().getFullYear(); ev++) {
            vm.years.push(ev);
        }
        vm.dayz = {};
        //Kiválasztott év (alapból az aktuális)
        vm.selectedYear = new Date().getFullYear();
        //Hónapok
        vm.months = [
            {
                text: gettextCatalog.getString('January'),
                value: 0
            }, {
                text: gettextCatalog.getString('February'),
                value: 1
            }, {
                text: gettextCatalog.getString('March'),
                value: 2
            }, {
                text: gettextCatalog.getString('April'),
                value: 3
            }, {
                text: gettextCatalog.getString('May'),
                value: 4
            }, {
                text: gettextCatalog.getString('June'),
                value: 5
            }, {
                text: gettextCatalog.getString('July'),
                value: 6
            }, {
                text: gettextCatalog.getString('August'),
                value: 7
            }, {
                text: gettextCatalog.getString('September'),
                value: 8
            }, {
                text: gettextCatalog.getString('October'),
                value: 9
            }, {
                text: gettextCatalog.getString('November'),
                value: 10
            }, {
                text: gettextCatalog.getString('December'),
                value: 11
            }
        ];
        //Kiválasztott hónap
        vm.yearValue = new Date().getFullYear();
        vm.selectedYear = new Date().getFullYear();
        vm.monthValue = new Date().getMonth();
        vm.selectedMonth = new Date().getMonth();
        //Napok nevei (rövidítve)
        vm.days = [
            gettextCatalog.getString('SundayAbbr'),
            gettextCatalog.getString('MondayAbbr'),
            gettextCatalog.getString('TuesdayAbbr'),
            gettextCatalog.getString('WednesdayAbbr'),
            gettextCatalog.getString('ThursdayAbbr'),
            gettextCatalog.getString('FridayAbbr'),
            gettextCatalog.getString('SaturdayAbbr')
        ];
        //Edzők listája, kiválasztott edző
        vm.coachList = [];
        //Szakosztályok listája, kiválasztott szakosztály
        vm.sectionList = [];
        vm.selectedSection = 0;
        //Korcsoportok listája, kiválasztott korcsoport
        vm.ageGroupList = [];
        vm.selectedAgeGroup = 0;
        vm.refreshAthletesButton = false;
        vm.saveButton = false;
        vm.saveButtonEnabled = true;
        vm.closeButton = false;
        vm.acceptButton = false;
        vm.rejectButton = false;
        vm.attendanceOverChangeAthleteNames = '';
        //Havi jelenléti utolsó kitöltött nap
        vm.attendanceLastFilledDay = 0;
        //Minden korcsoportot egyszerre tölt az edző
        vm.allAgeGroupfill = false;
        //A szerkesztés alatt álló havi jelenléti
        vm.editedAttendance = {};
        //Jelenlét hiba volt a mentés során
        vm.hasErrorAtSave = false;
        //Visszautasítás indoka kerül bele
        vm.rejectAttendanceText = '';

        vm.attendanceSelect = attendanceSelect;
        vm.saveAttendance = saveAttendance;
        vm.refreshAthletes = refreshAthletes;
        vm.closeMonthlyAttendance = closeMonthlyAttendance;
        vm.rejectMonthlyAttendance = rejectMonthlyAttendance;
        vm.closerejectingWindow = closerejectingWindow;
        vm.rejectAttendance = rejectAttendance;
        vm.acceptMonthlyAttendance = acceptMonthlyAttendance;
        // coach dropdown
        vm.coachDataBound = coachDataBound;
        vm.coachSelect = coachSelect;
        // section dropdown
        vm.sectionDataBound = sectionDataBound;
        vm.sectionSelect = sectionSelect;
        // ageGroup dropdown
        vm.ageGroupDataBound = ageGroupDataBound;
        vm.ageGroupSelect = ageGroupSelect;
        // year dropdown
        vm.yearSelect = yearSelect;
        // month dropdown
        vm.monthSelect = monthSelect;
        vm.goToNewAthlete = goToNewAthlete;
        vm.goToOlderMonthlyAttendanceReports = goToOlderMonthlyAttendanceReports;
        vm.CopyTimeAndPlaceFromWeeklyPlan = CopyTimeAndPlaceFromWeeklyPlan;
        vm.print = print;
        vm.runOnce = 0;
        vm.openTrainingDiaryAfterSave = false;

        vm.defaultSection = undefined;
        vm.defaultAgeGroup = undefined;
        vm.defaultCoach = undefined;
        vm.yearValue = vm.selectedYear;
        vm.defaultMonth = undefined;
        var JelenletTipus =
        {
            IgazoltHianyzas: 0,
            Serules: 1,
            Verseny: 2,
            Betegseg: 3
        };
        // Default beállítások
        vm.attendanceChecked = [JelenletTipus.IgazoltHianyzas, JelenletTipus.Serules, JelenletTipus.Betegseg];

        activate();

        function openTrainingDiary() {
            vm.openTrainingDiaryAfterSave = true;
            if (!vm.readOnlyAttendance) {
                gridNew.endEdit();
            }
            if (vm.readOnlyAttendance) {
                paramHelper.setParams([{'HaviJelenletiFejId': vm.editedAttendance.fej.Id}]);
                $state.go('administration.trainingDiary');
            } else {
                saveAttendance();
            }
        }

        function findLine(sportolo) {
            for (var i = 0; i < vm.activePeriods.length; i++) {
                if (vm.activePeriods[i].IdSportolo == sportolo) {
                    return vm.activePeriods[i];
                }
            }
        }

        function activate() {
            $q.all([paramHelper.getParams([], ['monthlyAttendance.id', 'monthlyAttendance.sectionId', 'monthlyAttendance.ageGroupId', 'monthlyAttendance.coachId', 'monthlyAttendance.year', 'monthlyAttendance.month', 'monthlyAttendance.autoSave']), authenticationService.getRight('EDZO'), authenticationService.getRight('ADMIN'), authenticationService.getRight('SZAKOSZTVEZ'), authenticationService.getRight('TECHIGAZGATO'), authenticationService.getRight('HJALAIR1'), authenticationService.getRight('HJALAIR2'), authenticationService.getRight('HJFELVIHET'), authenticationService.getRight('MEGTSPORTLIST'), authenticationService.getRight('MEGTHAVIJEL'), getSystemSettingsValue('JELENLETNEKSZAMIT')])
                .then(function (results) {
                    vm.EDZO = results[1];
                    vm.ADMIN = results[2];
                    vm.SZAKOSZTVEZ = results[3];
                    vm.TECHIGAZGATO = results[4];
                    vm.HJALAIR1 = results[5];
                    vm.HJALAIR2 = results[6];
                    vm.HJFELVIHET = results[7];
                    vm.MEGTSPORTLIST = results[8];
                    vm.MEGTHAVIJEL = results[9];
                    if (results[10] && results[10] != null) {
                        vm.attendanceChecked = JSON.parse(results[10]);
                    }
                    if (!vm.MEGTHAVIJEL) {
                        log.permissionError(true);
                        return;
                    }
                    dataservice.getMonthlyAttendanceAutoSave().then(function (autoSave) {
                        if (vm.HJFELVIHET) {
                            vm.autoSave = autoSave.data;
                        } else {
                            vm.autoSave = false;
                        }
                        angular.element('#autoSaveCheckBox').ejCheckBox({checked: autoSave.data});
                    });
                    if (results[0]['monthlyAttendance.sectionId']) {
                        vm.defaultSection = results[0]['monthlyAttendance.sectionId'];
                    }

                    if (results[0]['monthlyAttendance.ageGroupId']) {
                        vm.defaultAgeGroup = results[0]['monthlyAttendance.ageGroupId'];
                    }
                    if (results[0]['monthlyAttendance.coachId']) {
                        vm.defaultCoach = results[0]['monthlyAttendance.coachId'];
                    }
                    if (results[0]['monthlyAttendance.year']) {
                        vm.selectedYear = results[0]['monthlyAttendance.year'];
                    }
                    if (results[0]['monthlyAttendance.month']) {
                        vm.selectedMonth = results[0]['monthlyAttendance.month'];
                    }
                    if (results[0]['monthlyAttendance.id'] && results[0]['monthlyAttendance.id'] !== -1) {
                        dataservice.monthlyAttendanceSelectById(results[0]['monthlyAttendance.id']).then(function (data) {
                            paramHelper.removeParam('monthlyAttendance.id');
                            createGrid(data.columns);
                            if (data.fej) {
                                vm.activePeriods = data.aktiv;
                                //paramHelper.removeParam('monthlyAttendance.id');
                                angular.element('#maEv').ejDropDownList('selectItemByValue', new Date(data.fej.Datum).getFullYear());
                                angular.element('#maHonap').ejDropDownList('selectItemByValue', new Date(data.fej.Datum).getMonth());
                                gridNew.dataSource = data.fej ? data.fej : [];
                                vm.defaultSection = data.fej.IdSzakosztaly ? data.fej.IdSzakosztaly : vm.defaultSection;
                                vm.defaultCoach = data.fej.IdEdzo ? data.fej.IdEdzo : vm.defaultCoach;
                                vm.defaultAgeGroup = data.fej.IdKorcsoport ? data.fej.IdKorcsoport : vm.defaultAgeGroup;
                                getCoaches();
                                viewMonthlyAttendance(data);
                            } else {
                                getCoaches();
                            }
                        });
                    } else {
                        getCoaches();
                    }
                });
        }

        function getSystemSettingsValue(valtozo) {
            return dataservice.getSystemSettingsValue(valtozo).then(function (response) {
                if (response.hasOwnProperty('retValue')) {
                    return response.retValue;
                }
                return null;
            });
        }

        function CopyTimeAndPlaceFromWeeklyPlan() {
            dataservice.CopyTimeAndPlaceFromWeeklyPlan(vm.editedAttendance.fej.Id).then(function (response) {
                if (response.data) {
                    if (response.data.HetfoHely.length > 0) {
                        vm.dayz.HetfoHely = response.data.HetfoHely;
                    }
                    if (response.data.HetfoIdo.length > 0) {
                        vm.dayz.HetfoIdo = response.data.HetfoIdo;
                    }
                    if (response.data.KeddHely.length > 0) {
                        vm.dayz.KeddHely = response.data.KeddHely;
                    }
                    if (response.data.KeddIdo.length > 0) {
                        vm.dayz.KeddIdo = response.data.KeddIdo;
                    }
                    if (response.data.SzerdaHely.length > 0) {
                        vm.dayz.SzerdaHely = response.data.SzerdaHely;
                    }
                    if (response.data.SzerdaIdo.length > 0) {
                        vm.dayz.SzerdaIdo = response.data.SzerdaIdo;
                    }
                    if (response.data.CsutortokHely.length > 0) {
                        vm.dayz.CsutortokHely = response.data.CsutortokHely;
                    }
                    if (response.data.CsutortokIdo.length > 0) {
                        vm.dayz.CsutortokIdo = response.data.CsutortokIdo;
                    }
                    if (response.data.PentekHely.length > 0) {
                        vm.dayz.PentekHely = response.data.PentekHely;
                    }
                    if (response.data.PentekIdo.length > 0) {
                        vm.dayz.PentekIdo = response.data.PentekIdo;
                    }
                    if (response.data.SzombatHely.length > 0) {
                        vm.dayz.SzombatHely = response.data.SzombatHely;
                    }
                    if (response.data.SzombatIdo.length > 0) {
                        vm.dayz.SzombatIdo = response.data.SzombatIdo;
                    }
                    if (response.data.VasarnapHely.length > 0) {
                        vm.dayz.VasarnapHely = response.data.VasarnapHely;
                    }
                    if (response.data.VasarnapIdo.length > 0) {
                        vm.dayz.VasarnapIdo = response.data.VasarnapIdo;
                    }
                } else {
                    log.errorMsg(gettextCatalog.getString('NoPlaceAndTimeDataFoundOnWeeklyTrainingPlan'));
                }
            });
        }

        function SetForAll() {
            //gridNew.editModule.batchSave();
            gridNew.endEdit();
            var names = '';
            var canSet = true;
            for (var i = 0; i < gridNew.dataSource.length; i++) {
                if ((gridNew.dataSource[i].JelenletOsszesen + gridNew.dataSource[i].HianyzasOsszesen) > vm.plannedTrainings) {
                    canSet = false;
                    names += gridNew.dataSource[i].SportoloNev + ', ';
                }
            }
            if (!canSet) {
                names = names.substring(0, names.length - 2);
                log.errorMsg(gettextCatalog.getString('PresencePlusAbsenceIsGreaterThanPlannedMonthlyAttendance') + ':<br><br>' + names);
                return;
            }
            for (var i = 0; i < gridNew.dataSource.length; i++) {
                gridNew.dataSource[i].HaviOsszEdzesszam = vm.plannedTrainings;
            }
        }

        //edzők lekérése
        function getCoaches() {
            return dataservice.coachSelect().then(
                function (data) {
                    vm.coachList = data.itemsList;
                }
            );
        }

        // Run after data bounded
        function coachDataBound() {
            if (vm.coachList.length === 1) {
                angular.element('#maEdzo').ejDropDownList('selectItemByValue', vm.coachList[0].value);
            } else {
                angular.element('#maEdzo').ejDropDownList('selectItemByValue', vm.defaultCoach);
            }
        }

        // Run after selection
        function coachSelect(args) {
            vm.selectedCoach = args.value;
            var item = ej.DataManager(vm.coachList).executeLocal(new ej.Query().where('value', 'equal', vm.selectedCoach))[0];
            vm.allAgeGroupfill = item.HaviJelenletiKorosztalyonkent;
            vm.sectionList = [];
            vm.ageGroupList = [];
            vm.selectedAgeGroup = 0;
            getSections();
        }

        //szakosztályok lekérése
        function getSections() {
            return dataservice.sectionDropDownList(vm.selectedCoach)
                .then(function (data) {
                    vm.sectionList = data.itemsList;
                });
        }

        // Run after data bounded
        function sectionDataBound() {
            if (vm.sectionList.length === 1) {
                angular.element('#maSzakosztaly').ejDropDownList('selectItemByValue', vm.sectionList[0].value);
            } else {
                angular.element('#maSzakosztaly').ejDropDownList('selectItemByValue', vm.defaultSection);
            }
        }

        // Run after selection
        function sectionSelect(args) {
            vm.selectedSection = args.value;
            getAgeGroups();
        }

        //korcsoportok lekérése
        function getAgeGroups() {
            return dataservice.ageGroupSelect(vm.selectedCoach, vm.selectedSection, new Date(vm.selectedYear, vm.selectedMonth, 1, 12, 0, 0))
                .then(function (data) {
                    vm.ageGroupList = data.itemsList;
                });
        }

        // Run after data bounded
        function ageGroupDataBound() {
            if (vm.ageGroupList.length === 1) {
                angular.element('#maKorosztaly').ejDropDownList('selectItemByValue', vm.ageGroupList[0].value);
            } else {
                if (vm.defaultAgeGroup) {
                    angular.element('#maKorosztaly').ejDropDownList('selectItemByValue', vm.defaultAgeGroup);
                } else if (vm.selectedAgeGroup) {
                    angular.element('#maKorosztaly').ejDropDownList('selectItemByValue', vm.selectedAgeGroup);
                }
            }
        }

        // Run after selection
        function ageGroupSelect(args) {
            vm.selectedAgeGroup = args.value;
        }

        // Run after selection
        function monthSelect(args) {
            vm.selectedMonth = args.value;
            getAgeGroups();
        }

        // Run after selection
        function yearSelect(args) {
            vm.selectedYear = args.value;
            getAgeGroups();
        }

        //'Választ' nyomógomb click
        function attendanceSelect() {
            vm.runOnce = 0;
            if (!vm.MEGTHAVIJEL) {
                log.permissionError();
                return;
            }
            // a syncfusion direktívák miatt
            $scope.$broadcast('submitted');
            if ($scope.form.$valid === false) {
                log.errorMsg('FillTheRequiredFields');
                return;
            }
            //gridNew.dataSource = [];
            var kivDatum = new Date(vm.selectedYear, (isNaN(vm.selectedMonth) ? 0 : vm.selectedMonth), 1, 0, 0, 0, 0);
            var dt = new Date(new Date().getFullYear(), new Date().getMonth(), 1, 0, 0, 0, 0);
            dt.setMonth(dt.getMonth() + 1);
            if (kivDatum >= dt) {
                log.errorMsg('WrongDate');
                return;
            }
            dataservice.monthlyAttendanceSelect(vm.selectedCoach, vm.selectedSection, vm.selectedAgeGroup,
                vm.selectedYear, (isNaN(vm.selectedMonth) ? 1 : Number(vm.selectedMonth)) + 1).then(function (data) {
                vm.saveButtonEnabled = true;
                createGrid(data.columns);
                viewMonthlyAttendance(data);
                vm.editedAttendance.aktiv = data.aktiv;
                vm.activePeriods = data.aktiv;
                if (data && data.fej && data.fej.Id) {
                    paramHelper.setParams([
                        {'monthlyAttendance.sectionId': vm.selectedSection},
                        {'monthlyAttendance.ageGroupId': vm.selectedAgeGroup},
                        {'monthlyAttendance.coachId': vm.selectedCoach},
                        {'monthlyAttendance.year': vm.selectedYear},
                        {'monthlyAttendance.month': vm.selectedMonth},
                        {'monthlyAttendance.id': data.fej.Id}
                    ]);
                } else {
                    paramHelper.setParams([
                        {'monthlyAttendance.sectionId': vm.selectedSection},
                        {'monthlyAttendance.ageGroupId': vm.selectedAgeGroup},
                        {'monthlyAttendance.coachId': vm.selectedCoach},
                        {'monthlyAttendance.year': vm.selectedYear},
                        {'monthlyAttendance.month': vm.selectedMonth},
                        {'monthlyAttendance.id': -1}
                    ]);
                }
            });
        }

        vm.rowNumber = 0;
        vm.columnNumber = 0;

        function createGrid(columns) {
            for (var i = 0; i < columns.length; i++) {
                if (columns[i].edit) {
                    columns[i].edit = tableEditorTemplate;
                }
                if (i === (columns.length - 1)) {
                    if (!gridNew) {
                        gridNew = new ej2.grids.Grid({
                            dataSource: [],
                            toolbar: ['ExcelExport'],
                            allowPaging: false,
                            allowExcelExport: true,
                            editSettings: {allowEditing: true, mode: 'Batch', showConfirmDialog: false},
                            //frozenColumns: 4,
                            locale: 'hu-HU',
                            gridLines: 'Both',
                            columns: columns,
                            cellSave: vm.cellSave,
                            queryCellInfo: queryCellInfo,
                            rowDataBound: function (args) {
                                $rootScope.compile(args.row, $scope);
                            },
                            rowSelected: vm.gridRowSelected,
                            actionComplete: function (args) {
                                if (args.requestType === 'paging' || args.requestType === 'refresh') {
                                    $rootScope.compile($('#GridNew'), $scope);
                                }
                            },
                            cellEdit: function (arg) {
                                vm.columnNumber = gridNew.columns.findIndex(function (element) {
                                    return element.field === arg.columnName;
                                });
                                vm.rowNumber = gridNew.dataSource.findIndex(function (element) {
                                    return element.Id === arg.rowData.Id;
                                });
                                if (arg.columnName.indexOf('Nap') !== -1) {
                                    var nap = arg.columnName.substring(3);
                                    var sor = findLine(arg.rowData.IdSportolo);
                                    var property = 'Nap' + nap;
                                    if (sor && sor[property] == '0') {
                                        arg.cancel = true;
                                    }
                                }
                                vm.AthleteUnderEditing = arg.rowData.SportoloNev;
                                vm.editedColumnName = arg.columnName;
                            }
                        });
                        gridNew.appendTo('#GridNew');
                        gridNew.keyConfigs['0'] = '0';
                        gridNew.keyConfigs['1'] = '1';
                        gridNew.keyConfigs['2'] = '2';
                        gridNew.keyConfigs['3'] = '3';
                        gridNew.keyConfigs['B'] = 'B';
                        gridNew.keyConfigs['S'] = 'S';
                        gridNew.keyConfigs['V'] = 'V';
                        gridNew.keyConfigs['H'] = 'H';
                        gridNew.keyConfigs['b'] = 'B';
                        gridNew.keyConfigs['ü'] = '191';
                        gridNew.keyConfigs['Ü'] = '191';
                        gridNew.keyConfigs['s'] = 'S';
                        gridNew.keyConfigs['v'] = 'V';
                        gridNew.keyConfigs['h'] = 'H';
                        gridNew.keyConfigs['numpad 0'] = '96';
                        gridNew.keyConfigs['numpad 1'] = '97';
                        gridNew.keyConfigs['numpad 2'] = '98';
                        gridNew.keyConfigs['numpad 3'] = '99';
                        var oldkeyAction = gridNew.keyboardModule.keyAction;

                        gridNew.keyboardModule.keyAction = function (args) {
                            if (args.key === '0') {
                                vm.editedColumnValue = '0';
                            }
                            if (args.key === '1') {
                                vm.editedColumnValue = '1';
                            }
                            if (args.key === '2') {
                                vm.editedColumnValue = '2';
                            }
                            if (args.key === '3') {
                                vm.editedColumnValue = '3';
                            }
                            if (args.key === 'B' || args.key === 'b') {
                                vm.editedColumnValue = 'B';
                            }
                            if (args.key === 'Ü' || args.key === 'ü') {
                                vm.editedColumnValue = 'Ü';
                            }
                            if (args.key === 'S' || args.key === 's') {
                                vm.editedColumnValue = 'S';
                            }
                            if (args.key === 'V' || args.key === 'v') {
                                vm.editedColumnValue = 'V';
                            }
                            if (args.key === 'H' || args.key === 'h') {
                                vm.editedColumnValue = 'H';
                            }
                            if (args.key === 'Escape') {
                                vm.AthleteUnderEditing = '';
                            }
                            if (args.key === 'Enter') {
                                gridNew.editModule.batchSave();
                                if (args.action === 'enter' && gridNew.dataSource[vm.rowNumber + 1]) {
                                    $timeout(function () {
                                        gridNew.editModule.editCell(vm.rowNumber + 1, gridNew.columns[vm.columnNumber].field);
                                    }, 250);
                                } else if (args.action === 'shiftEnter' && gridNew.dataSource[vm.rowNumber - 1]) {
                                    $timeout(function () {
                                        gridNew.editModule.editCell(vm.rowNumber - 1, gridNew.columns[vm.columnNumber].field);
                                    }, 250);
                                }
                            }
                            if (args.key === 'Tab') {
                                gridNew.editModule.batchSave();
                                if (args.action === 'tab' && gridNew.columns[vm.columnNumber + 1]) {
                                    $timeout(function () {
                                        gridNew.editModule.editCell(vm.rowNumber, gridNew.columns[vm.columnNumber + 1].field);
                                    }, 250);
                                } else if (args.action === 'shiftTab' && gridNew.columns[vm.columnNumber - 1]) {
                                    $timeout(function () {
                                        gridNew.editModule.editCell(vm.rowNumber, gridNew.columns[vm.columnNumber - 1].field);
                                    }, 250);
                                }
                            }
                            $scope.$apply();
                            oldkeyAction(args);
                        };
                        gridNew.toolbarClick = function (args) {
                            if (args.item.id === 'GridNew_excelexport') {
                                gridNew.excelExport();
                            }
                            if (args.item.id === 'GridNew_csvexport') {
                                gridNew.csvExport();
                            }
                            if (args.item.id === 'GridNew_print') {
                                gridNew.print({pageOrientation: 'Landscape'});
                            }
                        };
                    } else {
                        gridNew.columns = columns;
                        gridNew.refreshColumns();
                    }
                }
            }
        }

        function viewMonthlyAttendance(data) {
            console.log('viewMonthlyAttendance', data);
            vm.noAttendance = false;
            vm.hasAttendance = false;
            vm.hasSignedAttendance = false;
            vm.hasAcceptedAttendance = false;

            vm.editedAttendance.aktiv = data.aktiv;
            vm.editedAttendance.edzesek = data.edzesek;
            vm.editedAttendance.fej = data.fej;
            vm.editedAttendance.tetelek = data.tetelek;
            if (vm.editedAttendance.fej === null) {
                vm.noAttendance = true;
                vm.acceptButton = false;
                vm.rejectButton = false;
                vm.refreshAthletesButton = false;
                if ((vm.ADMIN || vm.SZAKOSZTVEZ) && vm.HJFELVIHET) {
                    //vm.refreshAthletesButton = true;
                    vm.saveButton = true;
                    vm.closeButton = false;
                } else if (vm.EDZO && vm.HJFELVIHET) {
                    //vm.refreshAthletesButton = true;
                    vm.saveButton = true;
                    vm.closeButton = true;
                } else {
                    vm.refreshAthletesButton = false;
                    vm.saveButton = false;
                    vm.closeButton = false;
                }
                createNewAttendance();
            } else {
                if (vm.editedAttendance.fej.EdzoAlairta) {
                    if (vm.editedAttendance.fej.SzakosztalyvezetoAlairta) {
                        vm.hasAcceptedAttendance = true;
                        vm.refreshAthletesButton = false;
                        vm.saveButton = false;
                        vm.closeButton = false;
                        vm.acceptButton = false;
                        vm.rejectButton = false;
                    } else {
                        vm.hasSignedAttendance = true;
                        if (vm.HJFELVIHET) {
                            vm.refreshAthletesButton = false;
                            vm.saveButton = false;
                            vm.closeButton = false;
                        }
                        if ((vm.ADMIN || vm.SZAKOSZTVEZ) && vm.HJALAIR1) {
                            vm.acceptButton = true;
                            vm.rejectButton = true;
                        } else {
                            vm.acceptButton = false;
                            vm.rejectButton = false;
                        }
                    }
                } else {
                    vm.hasAttendance = true;
                    vm.acceptButton = false;
                    vm.rejectButton = false;
                    if ((vm.ADMIN || vm.SZAKOSZTVEZ) && vm.HJFELVIHET) {
                        vm.refreshAthletesButton = true;
                        vm.saveButton = true;
                        vm.closeButton = true;
                    } else if (vm.EDZO && vm.HJFELVIHET) {
                        vm.refreshAthletesButton = true;
                        vm.saveButton = true;
                        vm.closeButton = true;
                    } else {
                        vm.refreshAthletesButton = false;
                        vm.saveButton = false;
                        vm.closeButton = false;
                    }
                }
                gridNew.dataSource = vm.editedAttendance.tetelek;
                vm.dayz = vm.editedAttendance.edzesek;
                // create('existing');
            }
            vm.AthleteUnderEditing = '';
            vm.readOnlyAttendance = !vm.saveButton;
            gridNew.editSettings.allowEditing = !vm.readOnlyAttendance;
            totalPercentageCount();
            athletesCountMonthlyAttendance();
            refreshAthletes();
        }

        //Melyik az adott év/hónap utolsó napja?
        function daysInMonth(anyDateInMonth) {
            return new Date(anyDateInMonth.getFullYear(), anyDateInMonth.getMonth() + 1, 0).getDate();
        }

        //Minden sportoló jelenlét százalék számítása
        function totalPercentageCount() {
            vm.valami = 0;
            vm.presenceTotalSum = 0;
            vm.presencePlannedTotalSum = 0;
            var db = 0;
            if (gridNew && gridNew.dataSource) {
                db = gridNew.dataSource.length;
            }
            for (var rec = 0; rec < db; rec++) {
                var jo = parseFloat(gridNew.dataSource[rec].JelenletOsszesen);
                vm.presenceTotalSum += jo;
                var hoe = parseFloat(gridNew.dataSource[rec].HaviOsszEdzesszam);
                vm.presencePlannedTotalSum += hoe;
                if (hoe === 0) {
                    vm.valami += 1;
                } else {
                    vm.valami += (jo / hoe);
                }
            }
            $timeout(function () {
                if (vm.presencePlannedTotalSum > 0) {
                    vm.presencePercentSum = (vm.valami / db * 100).toFixed(0);
                } else {
                    vm.presencePercentSum = 100;
                }
                $scope.$apply();
            }, 100);
        }

        function cellSave(arg) {
            console.log(arg);
            vm.AthleteUnderEditing = '';
            var propertyChanged = arg.columnName;
            if (!(propertyChanged.indexOf('Nap') == -1 && propertyChanged != 'HaviOsszEdzesszam')) {
                var sor = arg.rowData;
                var change = false;
                var value = '';
                prev = '';
                if (arg.value) {
                    if (propertyChanged != 'HaviOsszEdzesszam') { // ez szám, nem string
                        value = arg.value.trim();
                    } else {
                        value = arg.value;
                    }
                }
                if (arg.previousValue) {
                    if (propertyChanged != 'HaviOsszEdzesszam') {
                        prev = arg.previousValue.trim();
                    } else {
                        prev = arg.previousValue;
                    }
                }
                if (value != prev) {
                    // később frissül, ezért úgy állítom össze a teljes sort, hogy ami éppen megváltozott annak értéket adok, mert a sorban még a régi van
                    sor[propertyChanged] = value;
                    change = true;
                }
                var retObj = attendanceRowCount(sor);
                if (change) {
                    if (retObj.hiba) {
                        arg.value = prev;
                        gridNew.dataSource[sor.RowNumber - 1][propertyChanged] = prev;
                    } else {
                        gridNew.dataSource[sor.RowNumber - 1][propertyChanged] = value;
                        gridNew.dataSource[sor.RowNumber - 1].JelenletOsszesen = retObj.jelenlet;
                        gridNew.dataSource[sor.RowNumber - 1].HianyzasOsszesen = retObj.hianyzas;
                        gridNew.dataSource[sor.RowNumber - 1].JelenletSzazalek = retObj.jelenletSzazalek;
                        gridNew.dataSource[sor.RowNumber - 1].HianyzasSzazalek = retObj.hianyzasSzazalek;
                        gridNew.dataSource[sor.RowNumber - 1].VersenyOsszesen = retObj.verseny;
                        gridNew.dataSource[sor.RowNumber - 1].utolsoKitoltottNap = retObj.utolsoKitoltottNap;
                    }
                }
                if (propertyChanged != 'HaviOsszEdzesszam') {
                    vm.editedColumnValue = gridNew.dataSource[sor.RowNumber - 1][propertyChanged];
                }
            } else {
                gridNew.dataSource[arg.rowData.RowNumber - 1][propertyChanged] = arg.value;
            }

            vm.selectedAthleteName = '';
            $timeout(function () {
                vm.allowAthleteRefresh = true;
                $scope.$apply();
            }, 10);

            totalPercentageCount();
            gridNew.refresh();
        }

        //Adott sportoló jelenlét számítás, túltöltés figyelése
        function attendanceRowCount(record) {
            var ret = {};
            var jelenlet = 0;
            var hianyzas = 0;
            var verseny = 0;
            var utolsoToltott = 0;
            vm.attendanceOverChangeAthleteNames = '';
            for (var i = 1; i < 32; i++) {
                var value = record['Nap' + i] !== null ? record['Nap' + i].trim() : record['Nap' + i];
                if (value) {
                    utolsoToltott = i;
                    if (typeof value === 'string') {
                        value = value.toUpperCase();
                        switch (value) {
                            case '0':
                                hianyzas++;
                                break;
                            case '1':
                                jelenlet += 1;
                                break;
                            case '2':
                                jelenlet += 2;
                                break;
                            case '3':
                                jelenlet += 3;
                                break;
                            case 'H':
                                if (vm.attendanceChecked.indexOf(JelenletTipus.IgazoltHianyzas) !== -1) {
                                    jelenlet++;
                                }
                                break;
                            case 'S':
                                if (vm.attendanceChecked.indexOf(JelenletTipus.Serules) !== -1) {
                                    jelenlet++;
                                }
                                break;
                            case 'V':
                                verseny++;
                                if (vm.attendanceChecked.indexOf(JelenletTipus.Verseny) !== -1) {
                                    jelenlet++;
                                }
                                break;
                            case 'B':
                                if (vm.attendanceChecked.indexOf(JelenletTipus.Betegseg) !== -1) {
                                    jelenlet++;
                                }
                                break;
                            case 'Ü':
                                break;
                        }
                    }
                }
            }
            var dt = vm.editedAttendance.fej.Datum;
            record['utolsoKitoltottNap'] = new Date(new Date(dt).getFullYear(), new Date(dt).getMonth(), utolsoToltott);
            ret.utolsoKitoltottNap = record['utolsoKitoltottNap'];
            record['JelenletOsszesen'] = jelenlet;
            ret.jelenlet = jelenlet;
            record['HianyzasOsszesen'] = hianyzas;
            ret.hianyzas = hianyzas;
            record['VersenyOsszesen'] = verseny;
            ret.verseny = verseny;
            if (record['HaviOsszEdzesszam'] > 0) {
                record['JelenletSzazalek'] = (record['JelenletOsszesen'] / record['HaviOsszEdzesszam'] * 100).toFixed(0);
                record['HianyzasSzazalek'] = (record['HianyzasOsszesen'] / record['HaviOsszEdzesszam'] * 100).toFixed(0);
            } else {
                record['JelenletSzazalek'] = '100';
                record['HianyzasSzazalek'] = '0';
            }
            ret.jelenletSzazalek = record['JelenletSzazalek'];
            ret.hianyzasSzazalek = record['HianyzasSzazalek'];
            if ((record['HianyzasOsszesen'] + record['JelenletOsszesen']) > record['HaviOsszEdzesszam']) {
                vm.attendanceOverChangeAthleteNames += record['SportoloNev'] +
                    '<br>' + gettextCatalog.getString('PlannedMonthlyTrainingsNumbers') + ': ' + record['HaviOsszEdzesszam'] +
                    '<br>' + gettextCatalog.getString('SumPresence') + ': ' + record['JelenletOsszesen'] +
                    '<br>' + gettextCatalog.getString('MissedAttendance') + ': ' + record['HianyzasOsszesen'] +
                    '<br>';
            }
            if (vm.attendanceOverChangeAthleteNames.length > 0) {
                var swalText = gettextCatalog.getString('AttendanceGreaterThanPlanned') + '<br>' +
                    vm.attendanceOverChangeAthleteNames + '<br>' +
                    gettextCatalog.getString('hintOnMonthlyAttendeance_Presence') + '<br><br>' +
                    gettextCatalog.getString('PleaseCorrectTheDatas');
                log.htmlError(swalText);
                ret.hiba = true;
            } else {
                ret.hiba = false;
            }
            totalPercentageCount();
            return ret;
        }

        function saveFunction(forced) {
            return new Promise(function(resolve, reject) {
                if (!gridNew) {
                    log.warningMsg(gettextCatalog.getString('PleaseFinishEditingInGrid'));
                    return;
                }
                if (!gridNew || (!forced && vm.editedAttendance.fej.EdzoAlairta === true)) {
                    return;
                }
                var SaveButtonWasEnabled = vm.saveButtonEnabled;
                vm.saveButtonEnabled = false;
                $timeout(function () {
                    if (gridNew.isEdit === true && gridNew.editModule) {
                        gridNew.editModule.endEdit();
                    }
                    totalPercentageCount();
                    vm.hasErrorAtSave = false;
                    vm.editedAttendance.edzesek = vm.dayz;
                    console.log('saveMonthlyAttendance vm.editedAttendance', vm.editedAttendance);
                    dataservice.saveMonthlyAttendance(vm.editedAttendance).then(function (resp) {
                        console.log('saveMonthlyAttendance resp', resp);
                        vm.saveButtonEnabled = true;
                        var haveImputError = [];
                        resp.inputErrors.forEach(function (input) {
                            if (input.error != null) {
                                haveImputError += input.fieldName + '<br>';
                            }
                        });
                        if (haveImputError.length > 0) {
                            log.warningMsg(haveImputError);
                        } else {
                            if (resp && resp.res > 0) {
                                log.successMsg('AttendanceSaved');
                                if (vm.openTrainingDiaryAfterSave) {
                                    paramHelper.setParams([{'HaviJelenletiFejId': resp.res}]);
                                    $state.go('administration.trainingDiary');
                                } else {
                                    paramHelper.setParams([
                                        {'monthlyAttendance.sectionId': vm.selectedSection},
                                        {'monthlyAttendance.ageGroupId': vm.selectedAgeGroup},
                                        {'monthlyAttendance.coachId': vm.selectedCoach},
                                        {'monthlyAttendance.id': resp.res}
                                    ]);
                                    dataservice.monthlyAttendanceSelectById(resp.res).then(function (data) {
                                        viewMonthlyAttendance(data);
                                    });
                                }
                            } else {
                                vm.hasErrorAtSave = true;
                                log.errorMsg('Error Saving Monthly Attendance');
                            }
                        }
                        vm.netError = false;
                        resolve('saveok');
                    }, function (err) {
                        console.log('SaveError', err);
                        vm.saveButtonEnabled = SaveButtonWasEnabled;
                        vm.netError = true;
                        reject(err);
                    });
                }, 0);
            });
        };

        //Havi jelenléti mentése gomb click
        function saveAttendance(forced) {
            saveFunction(forced).then(function (response) {
                console.log('saveFunction response', response);
                return response;
            })
            .catch(function (err) {
                console.error('saveFunction err', err);
                if (err && err.data && err.data.ExceptionType && !err.data.ExceptionType.includes('Siker2Exception')) {
                    ShowError();
                }
                return err;
            });
        }

        //Sportolók frissítése gomb click
        function refreshAthletes() {
            if (!vm.editedAttendance.fej || vm.hasSignedAttendance || vm.hasAcceptedAttendance) {
                return true;
            }
            if (gridNew.isEdit === true && gridNew.editModule) {
                gridNew.editModule.endEdit();
            }
            // JELENLÉTIN LÉVŐ SPORTOLÓ ADATOK FRISSÍTÉSE
            dataservice.monthlyAttendanceRefreshAthleteData(vm.editedAttendance.fej.Id)
                .then(function (refreshData) {
                    for (var r = 0; r < refreshData.tetelek.length; r++) {
                        for (var ea = 0; ea < vm.editedAttendance.tetelek.length; ea++) {
                            if (refreshData.tetelek[r].IdSportolo == vm.editedAttendance.tetelek[ea].IdSportolo) {
                                vm.editedAttendance.tetelek[ea].SportoloNev = refreshData.tetelek[r].SportoloNev;
                                vm.editedAttendance.tetelek[ea].SzuletesiDatum = refreshData.tetelek[r].SzuletesiDatum;
                                //$scope.$apply();
                                //gridNew.editModule.batchSave();
                                break;
                            }
                        }
                    }
                });
            // JELENLÉTIN NEM SZEREPLŐ SPORTOLÓK HOZZÁADÁSA
            dataservice.getNewAthletesInSelectedYearMonth(vm.editedAttendance.fej.Id)
                .then(function (newAthletes) {
                    var maxRowNumber = 0;
                    for (var i = 0; i < newAthletes.tetelek.length; i++) {
                        var duplicate = false;
                        for (var e = 0; e < vm.editedAttendance.tetelek.length; e++) {
                            if (vm.editedAttendance.tetelek[e].RowNumber > maxRowNumber) {
                                maxRowNumber = vm.editedAttendance.tetelek[e].RowNumber;
                            }
                            if (newAthletes.tetelek[i].IdSportolo == vm.editedAttendance.tetelek[e].IdSportolo) {
                                duplicate = true;
                                break;
                            }
                        }
                        if (!duplicate) {
                            var ujTetel = newAthletes.tetelek[i];
                            ujTetel.Id = newAthletesId--;
                            ujTetel.ujsportolo = true;
                            ujTetel.RowNumber = ++maxRowNumber;
                            vm.editedAttendance.tetelek.push(ujTetel);
                            vm.editedAttendance.aktiv.push(newAthletes.aktiv[i]);
                        }
                    }
                    athletesCountMonthlyAttendance();
                    log.successMsg('AthletesRefreshingDone');
                    gridNew.refresh();
                });
        }

        //Lekéri a csoport létszámát, érkezők-távozók számát
        function athletesCountMonthlyAttendance() {
            var tol = new Date(vm.selectedYear, (isNaN(vm.selectedMonth) ? 0 : vm.selectedMonth), 1, 0, 0, 0);
            var ig = new Date(vm.selectedYear, (isNaN(vm.selectedMonth) ? 0 : vm.selectedMonth), daysInMonth(tol), 23, 59, 59);
            if (!vm.editedAttendance.fej) {
                return;
            }
            if (!vm.editedAttendance.fej.IdKorcsoport) {
                vm.editedAttendance.fej.IdKorcsoport = 0;
            }
            dataservice.getAthletesCountForMonthlyAttendance(vm.editedAttendance.fej.IdEdzo, vm.editedAttendance.fej.IdSzakosztaly, vm.editedAttendance.fej.IdKorcsoport, tol, ig)
                .then(function (data) {
                    vm.editedAttendance.fej.CsoportLetszam = data.Szam;
                    vm.editedAttendance.fej.Erkezett = data.SzamErkezett;
                    vm.editedAttendance.fej.Tavozott = data.SzamKilepett;
                });
        }

        //Havi jelenléti lezárása gomb click
        function closeMonthlyAttendance() {
            console.log('closeMonthlyAttendance');
            vm.editedAttendance.fej.EdzoAlairta = true;
            vm.editedAttendance.fej.EdzoAlairtaDatum = new Date().toDateString();
            saveFunction(true).then(function (response) {
                console.log('closeMonthlyAttendance saveFunction response', response);
                vm.readOnlyAttendance = true;
                gridNew.editSettings.allowEditing = !vm.readOnlyAttendance;
                return response;
            })
            .catch(function (err) {
                console.error('closeMonthlyAttendance saveFunction err', err);
                if (err && err.data && err.data.ExceptionType && !err.data.ExceptionType.includes('Siker2Exception')) {
                    ShowError();
                }
                vm.editedAttendance.fej.EdzoAlairta = false;
                vm.editedAttendance.fej.EdzoAlairtaDatum = null;
                return err;
            });
        }

        //Havi jelenléti visszautasítása gomb click
        function rejectMonthlyAttendance() {
            var dialogObj = angular.element('#rejectAttendanceWindow').data('ejDialog');
            dialogObj.open();
        }

        //Felugró ablak 'Mégsem' gomb click
        function closerejectingWindow() {
            var dialogObj = angular.element('#rejectAttendanceWindow').data('ejDialog');
            dialogObj.close();
        }

        //Felugró ablak 'Rendben' gomb click - visszautasítás elmentése
        function rejectAttendance() {
            if (vm.rejectAttendanceText.length > 0) {
                var dialogObj = angular.element('#rejectAttendanceWindow').data('ejDialog');
                dialogObj.close();
            } else {
                return;
            }
            var uzi = gettextCatalog.getString('SystemMessage') + '\n' + gettextCatalog.getString('The') + ' ' + vm.editedAttendance.fej.SzakosztalyNev;
            if (vm.editedAttendance.fej.KorcsoportNev) {
                uzi += ' - ' + vm.editedAttendance.fej.KorcsoportNev;
            }
            uzi += ' ' + vm.selectedYear + ' ' + vm.months[(isNaN(vm.selectedMonth) ? 0 : vm.selectedMonth)].text + ' ' + gettextCatalog.getString('MonthlyAttendanceReportRejected') + '\n' + gettextCatalog.getString('Cause') + ' ' + vm.rejectAttendanceText;
            vm.editedAttendance.fej.EdzoAlairta = false;
            vm.editedAttendance.fej.EdzoAlairtaDatum = '';
            saveAttendance();
            if (!vm.hasErrorAtSave) {
                vm.readOnlyAttendance = false;
                gridNew.editSettings.allowEditing = !vm.readOnlyAttendance;
                viewMonthlyAttendance(vm.editedAttendance);
                dataservice.messageSave(uzi, [vm.selectedCoach], vm.selectedSection).then(function () {
                });
            }
        }

        //Havi jelenléti szakosztályvezetői elfogadása
        function acceptMonthlyAttendance() {
            dataservice.monthlyAttendanceAcceptByLeader(vm.editedAttendance.fej.Id).then(function (data) {
                vm.editedAttendance.fej.SzakosztalyvezetoAlairta = true;
                vm.editedAttendance.fej.IdSzakosztalyvezetoAlairta = data.res;
                vm.editedAttendance.fej.SzakosztalyvezetoAlairtaDatum = data.date;
                viewMonthlyAttendance(vm.editedAttendance);
                log.successMsg('AttendanceAccepted');
                vm.readOnlyAttendance = true;
                gridNew.editSettings.allowEditing = !vm.readOnlyAttendance;
            });
        }

        //Új havi jelenléti összesítő készítése
        function createNewAttendance() {
            dataservice.getLastMonthlyAttendanceTrainingLocationAndTime(vm.selectedCoach, vm.selectedSection, vm.selectedAgeGroup)
                .then(function (data) {
                    vm.editedAttendance.fej = {};
                    vm.editedAttendance.fej.CsoportLetszam = 0;
                    vm.editedAttendance.fej.Datum = new Date(vm.selectedYear, (isNaN(vm.selectedMonth) ? 0 : vm.selectedMonth), 1, 0, 0, 0);
                    vm.editedAttendance.fej.Erkezett = 0;
                    vm.editedAttendance.fej.Id = -1;
                    vm.editedAttendance.fej.IdEdzo = vm.selectedCoach;
                    vm.editedAttendance.fej.IdKorcsoport = vm.selectedAgeGroup;
                    vm.editedAttendance.fej.IdSzakosztaly = vm.selectedSection;
                    vm.editedAttendance.fej.KorosztalyosToltes = vm.allAgeGroupfill;
                    vm.editedAttendance.fej.Megjegyzes = '';
                    vm.editedAttendance.fej.Tavozott = 0;
                    vm.editedAttendance.fej.EdzoNev = angular.element('#maEdzo').data('ejDropDownList').getSelectedItem()[0].innerText;
                    vm.editedAttendance.fej.SzakosztalyNev = angular.element('#maSzakosztaly').data('ejDropDownList').getSelectedItem()[0].innerText;
                    if (angular.element('#maKorosztaly').data('ejDropDownList') && angular.element('#maKorosztaly').data('ejDropDownList').getSelectedItem().length > 0) {
                        vm.editedAttendance.fej.KorcsoportNev = angular.element('#maKorosztaly').data('ejDropDownList').getSelectedItem()[0].innerText;
                    } else {
                        vm.editedAttendance.fej.KorcsoportNev = 0;
                        vm.selectedAgeGroup = 0;
                    }

                    if (data !== null) {
                        vm.dayz.CsutortokHely = data.CsutortokHely;
                        vm.dayz.CsutortokIdo = data.CsutortokIdo;
                        vm.dayz.HetfoHely = data.HetfoHely;
                        vm.dayz.HetfoIdo = data.HetfoIdo;
                        vm.dayz.IdHaviJelenletiFej = -1;
                        vm.dayz.KeddHely = data.KeddHely;
                        vm.dayz.KeddIdo = data.KeddIdo;
                        vm.dayz.PentekHely = data.PentekHely;
                        vm.dayz.PentekIdo = data.PentekIdo;
                        vm.dayz.SzerdaHely = data.SzerdaHely;
                        vm.dayz.SzerdaIdo = data.SzerdaIdo;
                        vm.dayz.SzombatHely = data.SzombatHely;
                        vm.dayz.SzombatIdo = data.SzombatIdo;
                        vm.dayz.VasarnapHely = data.VasarnapHely;
                        vm.dayz.VasarnapIdo = data.VasarnapIdo;
                    } else {
                        vm.dayz.CsutortokHely = '';
                        vm.dayz.CsutortokIdo = '';
                        vm.dayz.HetfoHely = '';
                        vm.dayz.HetfoIdo = '';
                        vm.dayz.IdHaviJelenletiFej = -1;
                        vm.dayz.KeddHely = '';
                        vm.dayz.KeddIdo = '';
                        vm.dayz.PentekHely = '';
                        vm.dayz.PentekIdo = '';
                        vm.dayz.SzerdaHely = '';
                        vm.dayz.SzerdaIdo = '';
                        vm.dayz.SzombatHely = '';
                        vm.dayz.SzombatIdo = '';
                        vm.dayz.VasarnapHely = '';
                        vm.dayz.VasarnapIdo = '';
                    }
                    vm.editedAttendance.edzesek = vm.dayz;

                    dataservice.getAthletesForMonthlyAttendance(vm.selectedCoach,
                        vm.selectedSection, vm.selectedAgeGroup, vm.editedAttendance.fej.Datum)
                        .then(function (data2) {
                            gridNew.dataSource = data2.tetelek;
                            // create('new');
                            vm.editedAttendance.tetelek = gridNew.dataSource;
                            vm.editedAttendance.aktiv = data2.aktiv;
                            vm.activePeriods = data2.aktiv;
                            athletesCountMonthlyAttendance();
                        });
                });
        }

        function goToNewAthlete(Id) {
            if (vm.MEGTSPORTLIST) {
                swal({
                    title: gettextCatalog.getString('AreYouSureGo'),
                    text: gettextCatalog.getString('AreYouSureYouWantToGoToAthlete'),
                    type: 'warning',
                    showCancelButton: true,
                    confirmButtonText: gettextCatalog.getString('GoToAthlete')
                }).then(function (isConfirmed) {
                    if (isConfirmed.value) {
                        paramHelper.setParams([
                            {'athleteModify.sportoloId': Id}
                        ]);
                        $state.go('athletes.athletesManagement.athleteModify.dataSheet');
                    }
                });
            } else {
                log.permissionError();
            }
        }

        function goToOlderMonthlyAttendanceReports() {
            paramHelper.setParams([{'monthlyAttendance.coachId': vm.selectedCoach}, {'monthlyAttendance.year': vm.selectedYear}, {'monthlyAttendance.month': (isNaN(vm.selectedMonth) ? 0 : vm.selectedMonth)}]);
            $state.go('administration.monthlyAttendance.monthlyAttendanceList');
        }

        function getAccepterCoachById() {
            if (vm.editedAttendance.fej.IdSzakosztalyvezetoAlairta === null) {
                return '';
            } else {
                return $q(function (resolve) {
                    dataservice.getUserData(vm.editedAttendance.fej.IdSzakosztalyvezetoAlairta)
                        .then(function (args) {
                            resolve(args.retValue.name);
                        });
                });
            }
        }

        function print() {
            var max = daysInMonth(new Date(vm.editedAttendance.fej.Datum));
            $q.all([getAccepterCoachById()])
                .then(function (results) {
                    vm.reportParameters = {
                        Szakosztaly: vm.editedAttendance.fej.SzakosztalyNev,
                        EvHonap: (new Date(vm.editedAttendance.fej.Datum).getFullYear() + '. ' + vm.months[moment(vm.editedAttendance.fej.Datum).month()].text),
                        EdzoNeve: vm.editedAttendance.fej.EdzoNev,
                        FejId: vm.editedAttendance.fej.Id,
                        Datum29: max >= 29 ? '29' : ' ',
                        Datum30: max >= 30 ? '30' : ' ',
                        Datum31: max >= 31 ? '31' : ' ',
                        Nap1: 1 + ' ' + vm.days[new Date(new Date(vm.editedAttendance.fej.Datum).getFullYear(), new Date(vm.editedAttendance.fej.Datum).getMonth(), 1, 0, 0, 0).getDay()],
                        Nap2: 2 + ' ' + vm.days[new Date(new Date(vm.editedAttendance.fej.Datum).getFullYear(), new Date(vm.editedAttendance.fej.Datum).getMonth(), 2, 0, 0, 0).getDay()],
                        Nap3: 3 + ' ' + vm.days[new Date(new Date(vm.editedAttendance.fej.Datum).getFullYear(), new Date(vm.editedAttendance.fej.Datum).getMonth(), 3, 0, 0, 0).getDay()],
                        Nap4: 4 + ' ' + vm.days[new Date(new Date(vm.editedAttendance.fej.Datum).getFullYear(), new Date(vm.editedAttendance.fej.Datum).getMonth(), 4, 0, 0, 0).getDay()],
                        Nap5: 5 + ' ' + vm.days[new Date(new Date(vm.editedAttendance.fej.Datum).getFullYear(), new Date(vm.editedAttendance.fej.Datum).getMonth(), 5, 0, 0, 0).getDay()],
                        Nap6: 6 + ' ' + vm.days[new Date(new Date(vm.editedAttendance.fej.Datum).getFullYear(), new Date(vm.editedAttendance.fej.Datum).getMonth(), 6, 0, 0, 0).getDay()],
                        Nap7: 7 + ' ' + vm.days[new Date(new Date(vm.editedAttendance.fej.Datum).getFullYear(), new Date(vm.editedAttendance.fej.Datum).getMonth(), 7, 0, 0, 0).getDay()],
                        Nap8: 8 + ' ' + vm.days[new Date(new Date(vm.editedAttendance.fej.Datum).getFullYear(), new Date(vm.editedAttendance.fej.Datum).getMonth(), 8, 0, 0, 0).getDay()],
                        Nap9: 9 + ' ' + vm.days[new Date(new Date(vm.editedAttendance.fej.Datum).getFullYear(), new Date(vm.editedAttendance.fej.Datum).getMonth(), 9, 0, 0, 0).getDay()],
                        Nap10: 10 + ' ' + vm.days[new Date(new Date(vm.editedAttendance.fej.Datum).getFullYear(), new Date(vm.editedAttendance.fej.Datum).getMonth(), 10, 0, 0, 0).getDay()],
                        Nap11: 11 + ' ' + vm.days[new Date(new Date(vm.editedAttendance.fej.Datum).getFullYear(), new Date(vm.editedAttendance.fej.Datum).getMonth(), 11, 0, 0, 0).getDay()],
                        Nap12: 12 + ' ' + vm.days[new Date(new Date(vm.editedAttendance.fej.Datum).getFullYear(), new Date(vm.editedAttendance.fej.Datum).getMonth(), 12, 0, 0, 0).getDay()],
                        Nap13: 13 + ' ' + vm.days[new Date(new Date(vm.editedAttendance.fej.Datum).getFullYear(), new Date(vm.editedAttendance.fej.Datum).getMonth(), 13, 0, 0, 0).getDay()],
                        Nap14: 14 + ' ' + vm.days[new Date(new Date(vm.editedAttendance.fej.Datum).getFullYear(), new Date(vm.editedAttendance.fej.Datum).getMonth(), 14, 0, 0, 0).getDay()],
                        Nap15: 15 + ' ' + vm.days[new Date(new Date(vm.editedAttendance.fej.Datum).getFullYear(), new Date(vm.editedAttendance.fej.Datum).getMonth(), 15, 0, 0, 0).getDay()],
                        Nap16: 16 + ' ' + vm.days[new Date(new Date(vm.editedAttendance.fej.Datum).getFullYear(), new Date(vm.editedAttendance.fej.Datum).getMonth(), 16, 0, 0, 0).getDay()],
                        Nap17: 17 + ' ' + vm.days[new Date(new Date(vm.editedAttendance.fej.Datum).getFullYear(), new Date(vm.editedAttendance.fej.Datum).getMonth(), 17, 0, 0, 0).getDay()],
                        Nap18: 18 + ' ' + vm.days[new Date(new Date(vm.editedAttendance.fej.Datum).getFullYear(), new Date(vm.editedAttendance.fej.Datum).getMonth(), 18, 0, 0, 0).getDay()],
                        Nap19: 19 + ' ' + vm.days[new Date(new Date(vm.editedAttendance.fej.Datum).getFullYear(), new Date(vm.editedAttendance.fej.Datum).getMonth(), 19, 0, 0, 0).getDay()],
                        Nap20: 20 + ' ' + vm.days[new Date(new Date(vm.editedAttendance.fej.Datum).getFullYear(), new Date(vm.editedAttendance.fej.Datum).getMonth(), 20, 0, 0, 0).getDay()],
                        Nap21: 21 + ' ' + vm.days[new Date(new Date(vm.editedAttendance.fej.Datum).getFullYear(), new Date(vm.editedAttendance.fej.Datum).getMonth(), 21, 0, 0, 0).getDay()],
                        Nap22: 22 + ' ' + vm.days[new Date(new Date(vm.editedAttendance.fej.Datum).getFullYear(), new Date(vm.editedAttendance.fej.Datum).getMonth(), 22, 0, 0, 0).getDay()],
                        Nap23: 23 + ' ' + vm.days[new Date(new Date(vm.editedAttendance.fej.Datum).getFullYear(), new Date(vm.editedAttendance.fej.Datum).getMonth(), 23, 0, 0, 0).getDay()],
                        Nap24: 24 + ' ' + vm.days[new Date(new Date(vm.editedAttendance.fej.Datum).getFullYear(), new Date(vm.editedAttendance.fej.Datum).getMonth(), 24, 0, 0, 0).getDay()],
                        Nap25: 25 + ' ' + vm.days[new Date(new Date(vm.editedAttendance.fej.Datum).getFullYear(), new Date(vm.editedAttendance.fej.Datum).getMonth(), 25, 0, 0, 0).getDay()],
                        Nap26: 26 + ' ' + vm.days[new Date(new Date(vm.editedAttendance.fej.Datum).getFullYear(), new Date(vm.editedAttendance.fej.Datum).getMonth(), 26, 0, 0, 0).getDay()],
                        Nap27: 27 + ' ' + vm.days[new Date(new Date(vm.editedAttendance.fej.Datum).getFullYear(), new Date(vm.editedAttendance.fej.Datum).getMonth(), 27, 0, 0, 0).getDay()],
                        Nap28: 28 + ' ' + vm.days[new Date(new Date(vm.editedAttendance.fej.Datum).getFullYear(), new Date(vm.editedAttendance.fej.Datum).getMonth(), 28, 0, 0, 0).getDay()],
                        Nap29: max >= 29 ? (29 + ' ' + vm.days[new Date(new Date(vm.editedAttendance.fej.Datum).getFullYear(), new Date(vm.editedAttendance.fej.Datum).getMonth(), 29, 0, 0, 0).getDay()]) : ' ',
                        Nap30: max >= 30 ? (30 + ' ' + vm.days[new Date(new Date(vm.editedAttendance.fej.Datum).getFullYear(), new Date(vm.editedAttendance.fej.Datum).getMonth(), 30, 0, 0, 0).getDay()]) : ' ',
                        Nap31: max >= 31 ? (31 + ' ' + vm.days[new Date(new Date(vm.editedAttendance.fej.Datum).getFullYear(), new Date(vm.editedAttendance.fej.Datum).getMonth(), 31, 0, 0, 0).getDay()]) : ' ',
                        OsszJelenletiSzazalek: vm.presencePercentSum + '%',
                        Keszult: vm.editedAttendance.fej.EdzoAlairtaDatum == null ? '' : moment(vm.editedAttendance.fej.EdzoAlairtaDatum).format('YYYY. MM. DD') === 'Invalid date' ? '' : moment(vm.editedAttendance.fej.EdzoAlairtaDatum).format('YYYY. MM. DD'),
                        EdzoNeve2: vm.editedAttendance.fej.EdzoNev,
                        JovahagytaDatum: vm.editedAttendance.fej.SzakosztalyvezetoAlairtaDatum == null ? '' : moment(vm.editedAttendance.fej.SzakosztalyvezetoAlairtaDatum).format('YYYY. MM. DD') === 'Invalid date' ? '' : moment(vm.editedAttendance.fej.SzakosztalyvezetoAlairtaDatum).format('YYYY. MM. DD'),
                        Jovahagyta: results != null ? results[0] : ' ',
                        Korosztaly: vm.editedAttendance.fej.KorcsoportNev === null ? '' : vm.editedAttendance.fej.KorcsoportNev
                    };
                    if (printOpened) {
                        angular.element('#printDialog').ejDialog('open');
                    } else {
                        printOpened = true;
                        angular.element('#printDialog').ejDialog({
                            height: '99%',
                            width: '99%',
                            enablemodal: 'true',
                            'position-x': '0',
                            'position-y': '0',
                            target: '#mainContent',
                            enableresize: 'false'
                        });
                    }
                });
        }

        function gridRowSelected(arg) {
            vm.selectedAthleteName = arg.data.SportoloNev + ' - ' + arg.data.SzuletesiDatum;
            $timeout(function () {
                $scope.$apply();
            }, 10);
        }

        function yearDataBound() {
            angular.element('#maEv').ejDropDownList('selectItemByValue', vm.selectedYear);
        }

        function monthDataBound() {
            angular.element('#maHonap').ejDropDownList('selectItemByValue', vm.selectedMonth);
        }

        function CopyCellToColumn() {
            gridNew.dataSource.forEach(function (element) {
                var retObj = attendanceRowCount(element);
                var sor = findLine(element.IdSportolo);
                if (!(retObj.hiba || (sor && sor[vm.editedColumnName] === '0'))) {
                    var oldValue = element[vm.editedColumnName];
                    element[vm.editedColumnName] = vm.editedColumnValue;
                    //rollback
                    if (attendanceRowCount(element).hiba) {
                        element[vm.editedColumnName] = oldValue;
                        attendanceRowCount(element);
                    } else {
                        element.JelenletOsszesen = retObj.jelenlet;
                        element.HianyzasOsszesen = retObj.hianyzas;
                        element.JelenletSzazalek = retObj.jelenletSzazalek;
                        element.HianyzasSzazalek = retObj.hianyzasSzazalek;
                        element.VersenyOsszesen = retObj.verseny;
                        element.utolsoKitoltottNap = retObj.utolsoKitoltottNap;
                    }
                }
            });
            totalPercentageCount();
            gridNew.refresh();
        }

        vm.autoSaveCheckboxChanged = function (args) {
            dataservice.setMonthlyAttendanceAutoSave(args.value).then(function (result) {
                if (result.retValue) {
                    if (args.value) {
                        log.infoMsg('AutoSaveOn');
                    } else {
                        log.infoMsg('AutoSaveOFF');
                    }
                }
            });
        };

        $rootScope.monthlyAttendanceSaveInterval = $interval(function () {
            if (vm.autoSave) {
                saveAttendance();
            }
        }, 60000);

        function ShowError() {
            log.errorMsg(gettextCatalog.getString('NetError'));
            swal({
                title: gettextCatalog.getString('Error'),
                text: gettextCatalog.getString('NetError'),
                type: 'error',
                showCancelButton: false,
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false,
                confirmButtonText: gettextCatalog.getString('IUnderstood')
            }).then(function (isConfirmed) {
                if (isConfirmed.value) {
                    console.log('ShowError isConfirmed', isConfirmed);
                }
            });
        }
    }
})();
